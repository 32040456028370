<template>
  <div>
    <div class="chapter" v-if="mail_num">快递单号 &emsp; {{ mail_num }}</div>
    <el-timeline class="home">
      <el-timeline-item
        v-for="(respon, index) in respon"
        :key="index"
        :timestamp="respon.acceptTime"
        placement="top"
      >
        {{ respon.remark }}
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { getMail } from "../api/api.js";
import CryptoJS from "crypto-js";
import axios from "axios";
const keyStr = 'c5010250ed068a23';
    const iv = '5bd1110c0f968c0c';
    export const Encrypt =(word)=> {
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let srcs = CryptoJS.enc.Utf8.parse(word);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        let hexStr = encrypted.ciphertext.toString().toUpperCase();
        return hexStr.toString();
    } 
 
    //解密
    export const  Decrypt=(word) => {
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
        var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        let decrypt = CryptoJS.AES.decrypt(srcs, key, {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }
export default {
  name: "HomeView",
  data() {
    return {
      respon: [],
      mail_num: "",
      mailid: "",
      apid: '',
      bpm: '',
      mu: '',
      stamp: '',
      sign: ''
    };
  },
  mounted() {
    this.fetchURLParams(); 
    this.getMailNews();
  },
  methods: {
    getMailNews() {
      getMail(this.mail_num)
        .then((res) => {
          this.respon = res.traces.reverse();
        })
        .catch((err) => {
          alert("查询无果，请核对单号是否正确！");
        });
    },
    fetchURLParams() {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      this.apid = params.get('apid');
      this.bpm = params.get('bpm');
      this.mu = params.get('mu');
      this.stamp = params.get('stamp');
      this.sign = params.get('sign');
      let sercetMessage=Decrypt(this.bpm);
      let match = sercetMessage.match(/mailid=(\d+)/);
      if (match && match[1]) {
          this.mail_num = match[1];
      } else {
          alert("获取快递信息失败，请联系工作人员。")
      }
    },
  },
};
</script>
<style>
.chapter {
  position:fixed;
  width: 100%;
  top: 0;
  padding-top: 1.5%;
  z-index: 100; /* 确保章节在顶部 */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 为顶部元素添加阴影 */
  background-color: white; /* 背景色设置为白色，确保内容可见性 */
}
.home {
  text-align: left;
  width: 100%; /* 这会使div撑满容器宽度 */
  position:fixed;
  top:4.5%;
  left:-1.5%;
  height: 95%;  /* 视窗高度减去上部固定内容的高度 */
  overflow-y: auto;  /* 允许纵向滚动 */
  box-sizing: border-box; /* 包含 padding 和 border 在内的总宽度计算 */
}
.el-timeline-item {
  max-width: 100%; /* 确保内部项目不超出容器宽度 */
  box-sizing: border-box; /* 同上 */
}
</style>