import axios from 'axios'
import request from '../utils/request'

export function getMail (id) {
    return request({
        url:'/cotrackapi/api/track/mail/'+id,
        // url:'http://211.156.193.140:8000/cotrackapi/api/track/mail/'+id,
        method: 'get',
        headers: {
            ContentType: "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        dataType: "json",
        ContentType: "application/json;charset-utf-8",
    })
}
