import axios from 'axios'

const server = axios.create({
    timeout: 15000,
    baseURL: process.env.VUE_APP_BASE_URL,
    // baseURL: 'http://211.156.193.140:8000',
})
//请求拦截器，列如token信息可以在请求拦截里面操作
server.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        // .token和.Authorization根据后端需要的字段改
        config.headers.Authorization = '4394B1FC3B9044F3E050030A240B63FF';
        config.headers.token = token;
        // 加固定的请求头
        config.headers.version = 'ems_track_cn_1.0';
        config.headers.authenticate = '4394B1FC3B9044F3E050030A240B63FF';
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
//响应拦截器，可以处理接口请求通的处理，可以处理成功和失败的操作
server.interceptors.response.use(
    response => {
        // return response
        const data = response.data
        return data
    },
    err => {
        if (err.response && err.response.status === 401) {
            alert('未登录')
        }
        return err
    }
)
export default server